import { render, Component } from "preact";
import { throttle } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


let updateInterval = 500;

interface Linkable {
    url: string
} 

interface ProductInfo extends Linkable {
    name: string,
    picture_url: string,
    price: number,
    discount: number,
}

interface SuggestionInfo extends Linkable {
    text: string,
}

class Suggestion extends Component<
    SuggestionInfo & {focused: boolean},
    {}
> {

    handleClick(that, event: Event) {
        let url = new URL(this.props.url, document.baseURI);
        document.location.href = url.href;
        event.stopPropagation();
    }

    render() {
        return (
            <div 
                class={this.props.focused ? "search-result-item alert-warning" : "search-result-item"} 
                onMouseDown={(event: Event) => {this.handleClick(this, event)}}
            >
                <div class="d-flex align-items-center">
                    <a href={this.props.url} title="" class="stretched-link">{this.props.text}</a>
                </div>
            </div>
        )
    }
}

class Product extends Component<
    ProductInfo & {focused: boolean},
    {}
> {

    handleClick(that, event: Event) {
        let url = new URL(this.props.url, document.baseURI);
        document.location.href = url.href;
        event.stopPropagation();
    }

    render() {
        return (
            <div 
                class={this.props.focused ? "search-result-item alert-warning" : "search-result-item"} 
                onMouseDown={(event: Event) => {this.handleClick(this, event)}}
            >
                <div class="d-flex align-items-center">
                    <div class="search-result-item-img bg-img" style={{backgroundImage: `url(${this.props.picture_url})`}}></div>
                    <a href={this.props.url} title="" class="stretched-link">{this.props.name}</a>
                </div>

                <div class="search-result-item-price">
                    {
                        this.props.discount > 0 &&
                        <span class="old-price"><span class="mr-1">{this.props.price}</span><span class="text-muted smaller">₽</span></span>
                    }
                    <span class="current-price"><span class="mr-1">{this.props.price - this.props.discount}</span><span class="text-muted smaller">₽</span></span>
                </div>
            </div>
        )
    }
}


class Search extends Component<
  {
      isDesktop: boolean,
      root: HTMLElement
  },
  {
    suggestions: Array<SuggestionInfo>;
    products: Array<ProductInfo>,
    hidden: boolean,
    focused: number
  }
> {
    throttled_search: any

    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            products: [],
            hidden: false,
            focused: -1,
        };
        this.throttled_search = throttle(this.fetch_search, updateInterval)
    }

    async fetch_search(searchQuery) {
        const that = this;
        fetch(`/search/json?q=${encodeURI(searchQuery)}`)
            .then(function(response) {
                return response.json();
            })
            .then(function(data) {
                let suggestions = data['suggestions'];
                let products = data['products'] 
                that.setState({
                    suggestions: suggestions,
                    products: products
                });
            });
    }

    handleSearchInput(that, event: KeyboardEvent) {
        let input = event.target as HTMLInputElement;
        let searchQuery = input.value;
        if (event.key == 'Enter' && searchQuery.length > 0) {
            let url = new URL('/search/get', document.baseURI);
            document.location.href = url.href + `?q=${encodeURI(searchQuery)}`;
        }

        /*if (searchQuery.length == 0)
            this.setState({
                suggestions: [],
                products: []
            });*/
        else
            this.throttled_search(searchQuery);
    }

    handleInputKeyDown(that, event: KeyboardEvent) {
        let input = event.target as HTMLInputElement;
        let searchQuery = input.value;
        if (event.key == 'Enter' && searchQuery.length > 0) {
            let url = new URL('/search/get', document.baseURI);
            document.location.href = url.href + `?q=${encodeURI(searchQuery)}`;
        }
    }

    inputFocused(that, event: Event) {
        that.setState({
            hidden: false
        });
        if (that.state.products.length == 0 && that.state.products.length == 0) {
            let input = event.target as HTMLInputElement;
            let searchQuery = input.value;
            that.throttled_search(searchQuery)
        }
    }

    inputBlured(that, event: Event) {
        that.setState({
            hidden: true
        });
        if (this.props.isDesktop)
            this.props.root.style.display = 'none';
    }

    handleKeyDown(that, event: KeyboardEvent) {
        let focused = that.state.focused;
        let item_count = that.state.products.length + that.state.suggestions.length;
        if (event.key == "ArrowUp") {
            focused = Math.max(focused - 1, -1);
            that.setState({ focused: focused });
        }
        if (event.key == "ArrowDown") {
            focused = Math.min(focused + 1, item_count - 1);
            that.setState({ focused: focused });
        }
        if (focused > -1 && event.key == "Enter") {
            if (focused < this.state.suggestions.length) {
                let url = new URL(this.state.suggestions[focused].url, document.baseURI);
                document.location.href = url.href;
                event.stopPropagation();
            }
            else {
                let url = new URL(this.state.products[focused - this.state.suggestions.length].url, document.baseURI);
                document.location.href = url.href;
                event.stopPropagation();
            }
        }

    }

    render() {
        return (
            <div class="position-relative flex-fill" onKeyDown={(event: KeyboardEvent) => {this.handleKeyDown(this, event)}}>
                <input
                    type="text" 
                    class="form-control search-input typeahead" 
                    placeholder="Найти..." 
                    onInput={(event: KeyboardEvent) => {this.handleSearchInput(this, event)}}
                    onKeyDown={(event: KeyboardEvent) => {this.handleInputKeyDown(this, event)}}
                    onFocus={(event: Event) => {this.inputFocused(this, event)}}
                    onBlur={(event: Event) => {this.inputBlured(this, event)}}
                />
                <a href="#" title="Закрыть" class="search-box-close">
                <FontAwesomeIcon icon={"times" as IconProp} className="mr-1"/>
                </a>
                {
                    (this.state.products.length > 0 || this.state.suggestions.length > 0)  && (!this.state.hidden || this.props.isDesktop) &&
                    <div class="search-result">
                        {this.state.suggestions.map((suggestion: SuggestionInfo, i) => {
                            return (
                                <Suggestion
                                    text={suggestion.text}
                                    url={suggestion.url}
                                    focused={i == this.state.focused}
                                />
                            );
                        })}

                        {this.state.products.map((product: ProductInfo, i) => {
                            return (
                                <Product
                                    name={product.name}
                                    url={product.url}
                                    picture_url={product.picture_url}
                                    price={product.price}
                                    discount={product.discount}
                                    focused={i + this.state.suggestions.length == this.state.focused}
                                />
                            );
                        })}
                    </div>
                }
            </div>
        )
    }
}

let searchRootDesktop = document.getElementById("search-root-desktop")
let searchRootMobile = document.getElementById("search-root-mobile")
if (searchRootDesktop) {
    document.body.addEventListener("keydown", function (event: KeyboardEvent) {
        if(event.key === "Escape") {
            let searchDesktopInput = searchRootDesktop.querySelector('input') as HTMLInputElement;
            let searchMobileInput = searchRootMobile.querySelector('input') as HTMLInputElement;
            if (searchMobileInput)
                searchMobileInput.blur();
            if (searchDesktopInput)
                searchDesktopInput.blur();
        }
    }, false);

    render(<Search isDesktop={true} root={searchRootDesktop} />, searchRootDesktop);
    render(<Search isDesktop={false} root={searchRootMobile} />, searchRootMobile);
}