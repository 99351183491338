import { render, Component } from "preact";
import { getCookie, updateCartLengthCounter } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

var cart_uuid_container = document.getElementById(
  "cart_uuid"
) as HTMLInputElement;
var cart_uuid = cart_uuid_container.value;

document.cookie = `cart_uuid=${cart_uuid}`;

function Totals(props: {
  cart_length: number;
  total_price: number;
  total_discount: number;
}) {
  return (
    <div className="col-lg-4">
      <div className="rounded border p-4">
        <h4 className="mb-4">Ваша корзина</h4>
        <div className="d-flex justify-content-between mb-2">
          <span className="">Товары ({props.cart_length})</span>
          <span>
            <span className="h5 font-weight-bold mr-1">
              {props.total_price}
            </span>
            <span className="text-muted smaller">₽</span>
          </span>
        </div>

        {((props) => {
          if (props.total_discount > 0) {
            return (
              <div className="d-flex justify-content-between mb-4">
                <span>Скидка</span>
                <span className="text-danger">
                  <b>-&nbsp;</b>
                  <span className="h5 mr-1">{props.total_discount}</span>
                  <span className="smaller">₽</span>
                </span>
              </div>
            );
          }
        })(props)}

        <div className="d-flex justify-content-between border-top pt-4 mb-4">
          <span className="font-weight-bold">Итого</span>
          <span>
            <span
              id="total_discounted_price"
              className="h4 font-weight-bold mr-1">
              {props.total_price - props.total_discount}
            </span>
            <span className="text-muted smaller">₽</span>
          </span>
        </div>
        <a
          href="./checkout/"
          title=""
          className={
            props.cart_length == 0
              ? "btn btn-primary w-100 py-3 font-500 disabled"
              : "btn btn-primary w-100 py-3 font-500"
          }
        >
          Перейти к оформлению
        </a>
      </div>
    </div>
  );
}

function Cpi(props) {
  return (
    <div className="cart-item">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex position-relative cart-item-product mb-3 mb-md-0">
            <a
              href={props.url}
              className="d-inline-block bg-img rounded mr-3"
              style={{ backgroundImage: "url(" + props.image + ")" }}
            ></a>

            <div>
              <a
                href={props.url}
                className="d-inline-block text-decoration-none font-500"
              >
                {props.name}
              </a>

              {(() => {
                if (props.discount == 0) {
                  return (
                    <div className="item-price text-nowrap mb-1">
                      <span className="mr-2">
                        <span className="h5 font-weight-bold mr-1">
                          {props.price}
                        </span>
                        <span className="text-muted smaller">₽</span>
                      </span>
                    </div>
                  );
                }

                return (
                  <div className="item-price text-nowrap mb-1">
                    <span className="mr-2">
                      <span className="text-danger h5 font-weight-bold mr-1">
                        {props.price - props.discount}
                      </span>
                      <span className="old-price">
                        <span className="small text-muted mr-1">
                          {props.price}
                        </span>
                      </span>
                      <span className="text-muted smaller">₽</span>
                    </span>
                  </div>
                );
              })()}
              <a
                href="javascript:{}"
                onClick={() => props.delete()}
                className="small text-decoration-none text-secondary"
              >
                <FontAwesomeIcon icon={"fa-trash-alt" as IconProp} className="mr-2"/>
                Удалить
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="d-flex justify-content-between">
            <div className="items-quantity pt-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <button
                    onClick={() => props.increment(props.quantity - 1)}
                    style={{ minWidth: "2.5rem" }}
                    className="btn btn-decrement btn-outline-gray btn-minus"
                    type="button"
                  >
                    <strong>−</strong>
                  </button>
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  style={{ textAlign: "center" }}
                  className="form-control"
                  placeholder=""
                  value={props.quantity}
                  onChange={props.onQuantityEntered}
                />
                <div className="input-group-append">
                  <button
                    onClick={() => props.increment(props.quantity + 1)}
                    style={{ minWidth: "2.5rem" }}
                    className="btn btn-increment btn-outline-gray btn-plus"
                    type="button"
                  >
                    <strong>+</strong>
                  </button>
                </div>
              </div>
            </div>

            {(() => {
              if (props.discount == 0) {
                return (
                  <div className="item-price text-nowrap mb-1">
                    <span className="mr-2">
                      <span
                        id={props.id + "_product_total_price"}
                        className="h5 font-weight-bold mr-1"
                      >
                        {props.price * props.quantity}
                      </span>
                      <span className="text-muted smaller">₽</span>
                    </span>
                  </div>
                );
              }

              return (
                <div className="item-price">
                  <span className="d-block mb-n1">
                    <span
                      id={props.id + "_product_discounted_price"}
                      className="text-danger h4 font-weight-bold mr-1"
                    >
                      {(props.price - props.discount) * props.quantity}
                    </span>
                    <span className="text-muted smaller">₽</span>
                  </span>
                  <span className="old-price">
                    <span
                      id={props.id + "_product_total_price"}
                      className="small text-muted mr-1"
                    >
                      {props.price * props.quantity}
                    </span>
                    <span className="text-muted smaller">₽</span>
                  </span>
                </div>
              );
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}

interface CPI {
  cpi_id: number;
  quantity: number;
  price: number;
  discount: number;
  url: string;
  name: string;
  image: string;
}

class Cart extends Component<
  {},
  {
    cpiList: Array<CPI>;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      cpiList: [],
    };
  }
  timerID?: NodeJS.Timeout;

  async delete(cpi_id, cpi_index) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        cpi_id: cpi_id,
      }),
    };
    const response = await fetch("remove-from-cart/", requestOptions);
    let data = await response.json();
    let cpiList = this.state.cpiList.slice();
    cpiList.splice(cpi_index, 1);
    this.setState({
      cpiList: cpiList,
    });
  }

  async increment(cpi_id, cpi_index, new_quantity) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        cpi_id: cpi_id,
        quantity: new_quantity,
      }),
    };
    const response = await fetch("change_quantity/", requestOptions);
    let data = await response.json();
    let cpiList = this.state.cpiList.slice();
    cpiList[cpi_index].quantity = data["quantity"];
    this.setState({
      cpiList: cpiList,
    });
  }

  fetchCart() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      query: JSON.stringify({ get_all: true }),
    };
    fetch("get_cpi/", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          cpiList: data["cpi_list"],
        })
      );
  }

  componentDidMount() {
    this.fetchCart();
    this.timerID = setInterval(() => this.fetchCart(), 30 * 1000);
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }

  get_totals() {
    let total_price = 0;
    let total_discount = 0;
    for (var i in this.state.cpiList) {
      let cpi = this.state.cpiList[i];
      total_price += cpi.price * cpi.quantity;
      total_discount += cpi.discount * cpi.quantity;
    }

    return [total_price, total_discount];
  }

  render() {
    updateCartLengthCounter(this.state.cpiList.length);

    let totals = this.get_totals();
    let total_price = totals[0];
    let total_discount = totals[1];

    return (
      <>
        <div className="col-lg-8 mb-5 mb-lg-0">
          {this.state.cpiList.map((cpi, i) => {
            return (
              <Cpi
                key={cpi.cpi_id}
                id={cpi.cpi_id}
                name={cpi.name}
                price={cpi.price}
                discount={cpi.discount}
                quantity={cpi.quantity}
                image={cpi.image}
                url={cpi.url}
                increment={(new_value) =>
                  this.increment(cpi.cpi_id, i, new_value)
                }
                onQuantityEntered={(event) =>
                  this.increment(cpi.cpi_id, i, event.target.value)
                }
                delete={() => this.delete(cpi.cpi_id, i)}
              />
            );
          })}
        </div>
        <Totals
          total_price={total_price}
          total_discount={total_discount}
          cart_length={this.state.cpiList.length}
        />
      </>
    );
  }
}

if (document.getElementById("cart_root")) {
    render(<Cart />, document.getElementById("cart_root"));
}
