import { updateCartLengthCounter, getCookie } from "./utils";

function InitDetailsManagement() {
    const addToCartButton = document.getElementById('add_to_cart_button');
    const productId = (<HTMLInputElement>document.getElementById('product_id')).value;

    addToCartButton.addEventListener('click', function() {
        const quantity = (<HTMLInputElement>document.getElementById('quantity')).value;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            product_id: productId,
            quantity: quantity,
          }),
        };

        fetch("/cart/add-to-cart/", requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            updateCartLengthCounter(data['cart_size']);

            const alert_container = document.getElementById('alert_container');

            if (data['status'] == 'ok')
                addToCartButton.querySelector('span').innerHTML = "Добавлено"
            else {
                alert_container.innerHTML = data['message'];
                alert_container.classList.remove('d-none');
            }
          });
    });
}

if (document.getElementById('add_to_cart_button')) {
    InitDetailsManagement();
}

