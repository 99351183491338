import { render, Component } from "preact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


interface Info {
    iconClass: string;
    containerClass: string;
    text: string;
}


interface ProductThumb {
    url: string,
    pictureUrl: string
}

interface OrderData {
    date: string;
    code: string;
    shipMethod: string;
    shipAddres: string;
    procStatus: Info;
    payStatus: Info;
    price: number;
    url: string;
    items: Array<ProductThumb>;
}


function Order(props: {
    orderData: OrderData
}) {
    return(
        <div class="border rounded px-4 pt-4 mb-4">
            <div className="d-md-flex justify-content-between border-bottom pb-3 mb-4">
                <div className="mr-lg-5">
                    <div className="d-sm-flex justify-content-between align-items-center mr-lg-4 mb-1">
                        <a href={props.orderData.url} title="" className="h4 d-block text-dark text-decoration-none mb-0 mr-3">Заказ от {props.orderData.date}</a>
                        <span className={`badge ${props.orderData.procStatus.containerClass}`}>
                            <FontAwesomeIcon icon={props.orderData.procStatus.iconClass.replace('fa-', '') as IconProp} className="mr-1"/>
                            {props.orderData.procStatus.text}
                        </span>
                    </div>
                    <span className="d-block mb-1">
                        <span className="text-muted smaller mr-2">Номер заказа:</span>
                        <a href={props.orderData.url} title="" className="d-inline-block font-500 text-decoration-none">{props.orderData.code}</a>
                    </span>
                    <span className="d-block mb-1">
                        <span className="text-muted smaller mr-2">{props.orderData.shipMethod}:</span>
                        {props.orderData.shipAddres}
                    </span>
                </div>

                <div className="d-flex flex-wrap align-items-center d-md-block text-md-right">
                    <div className="item-price text-nowrap mb-md-1 mr-2 mr-md-0">
                        <span className="mr-2">
                            <span className="number h3 font-weight-bold mr-1">{props.orderData.price}</span>
                            <span className="text-muted smaller">₽</span>
                        </span>
                    </div>

                    <span className={`badge border ${props.orderData.payStatus.containerClass}`}>
                        <FontAwesomeIcon icon={props.orderData.payStatus.iconClass.replace('fa-', '') as IconProp} className="mr-1"/>
                        {props.orderData.payStatus.text}
                    </span>
                </div>
            </div>
            <div class="row mx-n1 mb-3">
                    {props.orderData.items.map((productThumb, i) => {
                        return (
                            <div class="col-md-1 col-2 px-1">
                                <div 
                                    className="bg-img ar-1-1 rounded position-relative mb-2"
                                    style={{ backgroundImage: `url(${productThumb.pictureUrl})` }}
                                >
                                    <a href={productThumb.url} title="" class="stretched-link"></a>
                                </div>
                            </div>
                        )
                    })}

                </div>
        </div>
    );
}

enum orderType {
    ACTIVE = 'active',
    DONE = 'done',
    ALL = 'all',
}

function ChooseBar(props: {
    typeChosenCallback: any,
}) {
    return (
        <div className="col-lg-4 col-md-6 px-0 mb-4">
            <select className="custom-select" onChange={(event) => props.typeChosenCallback(event.target.value)}>
                <option selected value={orderType.ALL}>Все заказы</option>
                <option value={orderType.ACTIVE}>Активные заказы</option>
                <option value={orderType.DONE}>Выполненные заказы</option>
            </select>
        </div>
    )
}


class OrdersInfo extends Component<
    {},
    {
        activeOrders: Array<OrderData>,
        doneOrders: Array<OrderData>,
        allOrders: Array<OrderData>,
        chosenType: string,
    }
> {

    currentPageActive: number;
    numPagesActive: number;
    currentPageDone: number;
    numPagesDone: number;
    currentPageAll: number;
    numPagesAll: number;

    constructor(props) {
      super(props);
      this.state = {
        activeOrders: [],
        doneOrders: [],
        allOrders: [],
        chosenType: orderType.ALL,
      };
        this.currentPageActive = 1;
        this.numPagesActive = 1;
        this.currentPageDone = 1;
        this.numPagesDone = 1;
        this.currentPageAll = 1;
        this.numPagesAll = 1;
    }
    
    fetchOrders(type: string) {
        if (type != orderType.ACTIVE && type != orderType.DONE && type != orderType.ALL)
            throw TypeError(`Unknown type of order: ${type}`)
        let page;
        let oldList;
        if (type == orderType.ACTIVE) {
            page = this.currentPageActive;
            oldList = this.state.activeOrders;
        }
        else if (type == orderType.DONE) {
            page = this.currentPageDone;
            oldList = this.state.doneOrders;
        }
        else if (type == orderType.ALL) {
            page = this.currentPageAll;
            oldList = this.state.allOrders;
        }
        
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        fetch(`/cart/orders.json/${type}?page=${page}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let list = data['orders'];
                if (type == orderType.ACTIVE) {
                    this.setState({
                        activeOrders: oldList.concat(list),
                    });
                    this.currentPageActive = data['page']
                    this.numPagesActive = data['num_pages']
                }
                else if (type == orderType.DONE) {
                    this.setState({
                        doneOrders: oldList.concat(list),
                    });
                    this.currentPageDone = data['page']
                    this.numPagesDone = data['num_pages']
                }
                else if (type == orderType.ALL) {
                    this.setState({
                        allOrders: oldList.concat(list),
                    });
                    this.currentPageAll = data['page']
                    this.numPagesAll = data['num_pages']
                }
            }
        );
    }

    typeChosen(that: any) {
        return (type: string) => {
            that.setState({
                chosenType: type
            });
            let orders;
            if (type == orderType.ACTIVE)
                orders = that.state.activeOrders;
            else if (type == orderType.DONE)
                orders = that.state.doneOrders;
            else if (type == orderType.ALL)
                orders = that.state.allOrders;
            if (orders.length == 0)
                that.fetchOrders(type);
        }
    }

    componentDidMount() {
        this.fetchOrders(this.state.chosenType);
    }

    loadMoreClicked(that) {
        if (that.state.chosenType == orderType.ACTIVE)
            that.currentPageActive += 1;
        else if (that.state.chosenType == orderType.DONE)
            that.currentPageDone += 1;
        else if (that.state.chosenType == orderType.ALL)
            that.currentPageAll += 1;
        that.fetchOrders(that.state.chosenType);
    }

    render() {
        let chosenList, page, numPages;
        if (this.state.chosenType == orderType.ACTIVE) {
            chosenList = this.state.activeOrders;
            page = this.currentPageActive;
            numPages = this.numPagesActive;
        }
        else if (this.state.chosenType == orderType.DONE) {
            chosenList = this.state.doneOrders;
            page = this.currentPageDone;
            numPages = this.numPagesDone;
        }
        else if (this.state.chosenType == orderType.ALL) {
            chosenList = this.state.allOrders;
            page = this.currentPageAll;
            numPages = this.numPagesAll;
        }
        return(
            <>
                <>
                    <ChooseBar typeChosenCallback={this.typeChosen(this)} />
                    {chosenList.map((orderData, i) => {
                        return (
                            <>
                                <Order orderData={orderData} />
                            </>
                        );
                    })}
                </>
                {
                    (numPages && page < numPages) && (
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-secondary px-5" onMouseDown={(event) => this.loadMoreClicked(this)}>Загрузить еще</button>
                        </div>
                    )
                }
                
            </>
        );
    }

}

if (document.getElementById("orders_root")) {
    render(<OrdersInfo />, document.getElementById("orders_root"));
}