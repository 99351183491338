import { library, icon, dom } from '@fortawesome/fontawesome-svg-core'
import {
    faCamera,
    faAngleDown,
    faBars,
    faLongArrowAltRight,
    faTimes,
    faPercentage,
    faFireAlt,
    faCertificate,
    faChevronUp,
    faAngleRight,
    faEnvelope,
    faBookmark,
    faHeart,
    faHashtag,
    faTrashAlt,
    faCreditCard,
    faMapMarkerAlt,
    faUserCircle,
    faCheck,
    faShoppingBag,
    faStar,
    faSlidersH,
    faUserSlash,
    faRoute,
    faClock,
    faRuler,
    faAngleLeft
} from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faInstagram, faVk, faYoutube, faFacebook, faTelegram } from '@fortawesome/free-brands-svg-icons'

let icons = [
    faCamera,
    faAngleDown,
    faBars,
    faLongArrowAltRight,
    faTimes,
    faPercentage,
    faFireAlt,
    faCertificate,
    faChevronUp,
    faAngleRight,
    faEnvelope,
    faBookmark,
    faHeart,
    faHashtag,
    faTrashAlt,
    faCreditCard,
    faMapMarkerAlt,
    faUserCircle,
    faCheck,
    faShoppingBag,
    faStar,
    faSlidersH,
    faUserSlash,
    faRoute,
    faClock,
    faRuler,
    faAngleLeft,

    faWhatsapp,
    faInstagram,
    faVk,
    faYoutube,
    faFacebook,
    faTelegram
]

// @ts-expect-error
library.add(icons)

dom.insertCss();
dom.watch()