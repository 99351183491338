import { fromJSON, Input } from "postcss";

let formList = document.querySelectorAll<HTMLButtonElement>('form.auth-form');
let formsArray = Array.from(formList);


for (let authForm of formsArray) {
    authForm.onsubmit = authFormSubmit;
}

async function authFormSubmit(this: GlobalEventHandlers, ev: Event) {
    let form = ev.currentTarget as HTMLFormElement;
    let formData = new FormData(form);
    let errorDiv = form.querySelector('.error')
    if (!form.checkValidity())
        return false
    ev.preventDefault();
    const response = await fetch('/users/login/', {
        method: 'POST',
        credentials: 'include',
        body: formData
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        if (data['result'] == 'success')
            location.reload();
        else
            errorDiv.innerHTML = data['error'];
    });
}